import { HowWeCode, Tools } from 'generated/apolloComponents'
import { SelectType } from '@/context/jobListContext'

export type HowWeCodeData = {
  [key: string]: string
}
export type HowWeTestData = {
  [key: string]: string
}
export type HowWeManageData = {
  [key: string]: string
}
export type ToolsData = {
  [key: string]: string
}

export const roles = [
  { value: 'backend', label: 'Backend' },
  { value: 'analyst', label: 'Analityk' },
  { value: 'devops', label: 'DevOps' },
  { value: 'frontend', label: 'Frontend' },
  { value: 'fullstack', label: 'Fullstack' },
  { value: 'administrator', label: 'Administrator' },
  { value: 'project_manager', label: 'Project Manager' },
  { value: 'qa', label: 'QA' },
  { value: 'tester', label: 'Tester' },
  { value: 'mobile', label: 'Mobile' },
  { value: 'architect', label: 'Architekt' },
  { value: 'support', label: 'Support' },
  { value: 'tech_lead', label: 'Tech Lead' },
  { value: 'embedded', label: 'Embedded' },
  { value: 'scrum_master', label: 'Scrum Master' },
  { value: 'security', label: 'Bezpieczeństwo' },
  { value: 'designer', label: 'UX/UI designer' },
  { value: 'gamedev', label: 'GameDev' },
  { value: 'data', label: 'Data Science/Engineering' },
  { value: 'consultant', label: 'Konsultant IT' },
]

export const skillsGeneral = [
  { value: 'Java', label: 'Java' },
  { value: 'JavaScript', label: 'JavaScript' },
  { value: 'Python', label: 'Python' },
  { value: 'C#', label: 'C#' },
  { value: 'C++', label: 'C++' },
  { value: 'PHP', label: 'PHP' },
  { value: 'Swift', label: 'Swift' },
  { value: 'Kotlin', label: 'Kotlin' },
  { value: 'Go', label: 'Go' },
  { value: 'Ruby', label: 'Ruby' },
]

export type Skills = {
  general?: SelectType[]
  frontend?: SelectType[]
  backend?: SelectType[]
  fullstack?: SelectType[]
  mobile?: SelectType[]
  embedded?: SelectType[]
  gamedev?: SelectType[]
  architect?: SelectType[]
  tester?: SelectType[]
  tech_lead?: SelectType[]
  administrator?: SelectType[]
  devops?: SelectType[]
}

export const skills: Skills = {
  general: skillsGeneral,
  frontend: [
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Angular', label: 'Angular' },
    { value: 'React', label: 'React' },
    { value: 'Vue.js', label: 'Vue.js' },
    { value: 'HTML', label: 'HTML' },
  ],
  backend: [
    { value: 'Scala', label: 'Scala' },
    { value: 'Java', label: 'Java' },
    { value: 'C#', label: 'C#' },
    { value: 'PHP', label: 'PHP' },
    { value: 'C++', label: 'C++' },
    { value: 'SQL', label: 'SQL' },
    { value: 'Ruby', label: 'Ruby' },
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Go', label: 'Go' },
  ],
  fullstack: [
    { value: 'Scala', label: 'Scala' },
    { value: 'Java', label: 'Java' },
    { value: 'C#', label: 'C#' },
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'PHP', label: 'PHP' },
    { value: 'C++', label: 'C++' },
    { value: 'Ruby', label: 'Ruby' },
    { value: 'Salesforce', label: 'Salesforce' },
  ],
  mobile: [
    { value: 'Kotlin', label: 'Kotlin' },
    { value: 'Swift', label: 'Swift' },
    { value: 'Java', label: 'Java' },
    { value: 'C#', label: 'C#' },
    { value: 'React Native', label: 'React Native' },
  ],
  embedded: [
    { value: 'C++', label: 'C++' },
    { value: 'C', label: 'C' },
    { value: 'Python', label: 'Python' },
    { value: 'Java', label: 'Java' },
  ],
  gamedev: [
    { value: 'C++', label: 'C++' },
    { value: 'C#', label: 'C#' },
    { value: 'Java', label: 'Java' },
  ],
  architect: skillsGeneral,
  tester: skillsGeneral,
  tech_lead: skillsGeneral,
  administrator: skillsGeneral,
  devops: skillsGeneral,
}

export const experienceLevel = [
  { value: 'intern', label: 'Intern' },
  { value: 'junior', label: 'Junior' },
  { value: 'medium', label: 'Mid' },
  { value: 'senior', label: 'Senior' },
]

export const employmentTypes = [
  { value: 'full_time', label: 'Full-time' },
  { value: 'part_time', label: 'Part-time' },
  { value: 'freelance', label: 'Freelance' },
]

export const contracts = {
  contractB2b: 'common:b2b',
  contractEmployment: 'common:employment',
  contractOther: 'common:other',
}

export const appContracts = {
  b2b: 'common:b2b',
  employment: 'common:employment',
  contract: 'common:other',
}

export const how_we_code: HowWeCodeData = {
  title: 'Praca z kodem',
  styleGuide: 'Ustalone konwencje w kodzie',
  codeReview: 'Code review',
  pairProgramming: 'Pair programming',
  staticCodeAnalysis: 'Statyczna analiza kodu',
  tdd: 'TDD',
  bdd: 'BDD',
  codeMetrics: 'Metryki kodu',
  knowledgeDatabase: 'Baza wiedzy',
  versionControlDescription: 'Kontrola wersji',
  version_control_placeholder: 'np. Git, SVN, Mercurial',
}

export const how_we_test: HowWeTestData = {
  title: 'Testowanie',
  unit: 'Testy jednostkowe',
  integration: 'Testy integracyjne',
  system: 'Testy systemowe',
  security: 'Pentesty',
  performance: 'Testy wydajnościowe',
  manualTesting: 'Testowanie manualne',
  automatedTesting: 'Automatyzacja testów',
  ci: 'CI',
}

export const how_we_manage: HowWeManageData = {
  title: 'Zarządzanie projektami',
  methodology: 'Metodyka',
  methodology_placeholder: 'np. Scrum, XP, Lean, Waterfall',
  architecturalDecisions: 'Decyzje architektoniczne podejmuje',
  architectural_decisions_placeholder: 'np. architekt, CTO, zespół',
  techDecisions: 'Technologię wybiera',
  tech_decisions_placeholder: 'np. architekt, CTO, zespół',
  projectTeam: 'Skład zespołu projektowego',
  project_team_info: 'Podaj przybliżone liczby',
  teamDev: 'Programiści',
  teamQa: 'Testerzy',
  teamPm: 'Project Managerowie',
  teamDesign: 'Designerzy',
  teamRemarks: 'Inne osoby w teamie / uwagi',
  concurrentProjects: 'Liczba równoległych projektów',
  projectSoftware: 'Software do zarządania projektami',
  project_software_placeholder: 'np. JIRA, Asana, Trello',
  canSwitchProjects: 'Możliwość zmiany projektu',
}

export const hwm: string[] = [
  'methodology',
  'architecturalDecisions',
  'techDecisions',
  'projectTeam',
  'projectTeamInfo',
  'teamDev',
  'teamQa',
  'teamPm',
  'teamDesign',
  'teamRemarks',
  'concurrentProjects',
  'projectSoftware',
  'canSwitchProjects',
]

export const toolsStrings: ToolsData = {
  title: 'Narzędzia',
  laptop: 'Laptop',
  pc: 'Komputer stacjonarny',
  additionalMonitor: 'Dodatkowy monitor',
  headphones: 'Słuchawki',
  container: 'Osobista szafka',
  phone: 'Telefon służbowy',
  freeSelection: 'Swoboda doboru narzędzi',
  system: 'System operacyjny',
  windows: 'Windows',
  linux: 'Linux',
  osx: 'OS X',
}

export const environment = {
  title: 'Środowisko pracy',
  tech_supervisor: 'Przełożony techniczny',
  open_space: 'Open space',
  rooms: 'Pokoje',
  dress_code: 'Dress code',
  elastic_hours: 'Elastyczne godziny pracy',
  remotePossible: 'Praca zdalna',
  remote_possible_info: 'W procentach',
  working_hours: 'Godziny pracy biura',
  paidVacations: 'Płatny urlop',
  paid_vacations_placeholder: 'dni rocznie',
  business_trips: 'Wyjazdy służbowe',
  business_trips_placeholder:
    'np. Możliwe kilkudniowe wyjazdy co kilka miesięcy',
}
