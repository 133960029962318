/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import {
  Company,
  HowWeCode,
  HowWeManage,
  HowWeTest,
  Job,
  Location,
  Readable,
  Tools,
  JobLocation,
  Benefit,
  Tech,
  Maybe,
} from 'generated/apolloComponents'
import forEach from 'lodash/forEach'
import useTranslation from 'next-translate/useTranslation'
import {
  how_we_code,
  how_we_manage,
  how_we_test,
  toolsStrings,
  contracts,
  hwm,
} from '@/data/jobData'
import IconData from '@/components/molecules/IconData'

export interface ColorBoardIconProps {
  width?: number
  height?: number
  color?: string
  className?: string
}
export interface BoardIconProps {
  active?: boolean
  width?: number
  height?: number
  className?: string
}

type FilterObject = {
  [key: string]: string
}

interface JobBreadcrumbs {
  title: string
  url?: string
}

// Check if object is empty
export const isEmpty = (obj: object) =>
  obj && !Object.values(obj).filter((e) => typeof e !== 'undefined').length

export const nonEmptyPropertiesCount = (obj: object): number =>
  obj &&
  Object.values(obj).filter((e: any) => {
    switch (typeof e) {
      case 'undefined':
        return false
      case 'object':
        return nonEmptyPropertiesCount(e)
      case 'string':
        return e.length
      default:
        return true
    }
  }).length
// Calculate time reading based on text length
export const getReadingTime = (
  textLength: number,
  wordsPerMinute: number = 225
): number => Math.ceil(textLength / wordsPerMinute)

export const groupBy = function (xs: any, key: string): object {
  return xs.reduce((rv: string[][], x: any) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const arrayToObject = (array: string[]) => {
  const ArrayOfObjects: Array<object> = []
  array.forEach((element: string) => {
    ArrayOfObjects.push({ value: element, label: element })
  })
  return ArrayOfObjects
}

export const arrayOfObjectToStringArrayByKey = (
  array: object[],
  key: string
): string[] => {
  const newArray: string[] = []
  array.forEach((item: any) => {
    newArray.push(item[key])
  })

  return newArray
}

export const mapBenefits = (array: Benefit[]): string[] => {
  const newArray: string[] = []
  array.map((x) => newArray.push(`${x.category}.${x.name}`))

  return newArray
}

export const arrayToString = (
  array: Array<string> | Array<object>,
  key?: string,
  callback?: Function
) => {
  if (typeof array[0] === 'string') {
    return array.join(',')
  }
  if (key) {
    if (callback) {
      return array.map((elem: any) => callback(elem[key])).join(',')
    }
    return array.map((elem: any) => elem[key]).join(',')
  }
}

export const getCurrentDate = (separator: String = '.') => {
  const newDate = new Date()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()

  return `${month < 10 ? `0${month}` : `${month}`}${separator}${year}`
}

export const getContractTypes = (job: Job): string[] => {
  const types: string[] = []
  job.contractB2b && types.push(contracts.contractB2b)
  job.contractEmployment && types.push(contracts.contractEmployment)
  job.contractOther && types.push(contracts.contractOther)

  return types
}

export const getDaysRemaining = (job: Job): number => {
  const oneDay = 24 * 60 * 60 * 1000
  const firstDate = new Date(job.endsAt).getTime()
  const secondDate = new Date().getTime()

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
  return diffDays
}

export const getWordForm = (
  inputNum: number,
  singular: string,
  plural: string,
  pluralGenitive: string
): any => {
  const num = Math.abs(inputNum)
  if (num === 1) return singular
  const rem10 = num % 10
  const rem100 = num % 100
  if (rem10 > 4 || rem10 < 2 || (rem100 < 15 && rem100 > 11))
    return pluralGenitive
  return plural
}

const selectCity = (job: Job) => {
  if (job.locations?.length) {
    return job.locale === 'pl'
      ? encodeURIComponent(job.locations[0].location?.cityPl as string)
      : encodeURIComponent(job.locations[0].location?.cityEn as string)
  }
  return 'remote'
}

export const generateJobBreadcrumbs = (
  job: Job,
  lang = 'pl'
): JobBreadcrumbs[] | [] => {
  const { t } = useTranslation()
  const breadcrumbs: JobBreadcrumbs[] = []

  if (job.locations && job.locations.length > 0) {
    breadcrumbs.push({
      title:
        lang === 'pl'
          ? (job.locations[0].location?.cityPl as string)
          : (job.locations[0].location?.cityEn as string),
      url: `/companies/jobs/s/city,${
        lang === 'pl'
          ? (job.locations[0].location?.cityPl as string)
          : (job.locations[0].location?.cityEn as string)
      }`,
    })
  } else if (job.remote) {
    breadcrumbs.push({
      title: t('common:remote'),
      url: `/companies/jobs/s/city,remote`,
    })
  }

  if (job.role) {
    breadcrumbs.push({
      title: t(`common:role_${job.role}`),
      url: `/companies/jobs/s/role,${job.role}`,
    })
  }

  if (job.mainTechnology) {
    breadcrumbs.push({
      title: job.mainTechnology as string,
      url: `/companies/jobs/s/skills,${job.mainTechnology}`,
    })
  } else if (job.technologyTags?.length) {
    breadcrumbs.push({
      title: job.technologyTags[0] as string,
      url: `/companies/jobs/s/skills,${encodeURIComponent(
        job.technologyTags[0]
      )}`,
    })
  }

  breadcrumbs.push({
    title: job.position as string,
  })

  return breadcrumbs
}

export const getLocationsWithAddresses = (job: Job, key: string) => {
  const locations: IconData[] = []
  forEach(job.locations)

  job.locations?.forEach((item: any) => {
    locations.push({ subData: item.address, data: item.location[key] })
  })

  return locations
}

function camelToUnderscore(key: string) {
  const result = key.replace(/([A-Z])/g, ' $1')
  return result.split(' ').join('_').toLowerCase()
}
interface HowWeItem {
  label: string
  value: string | number | boolean | null | undefined
}

export const getWorkFlowManage = (data: Job | Company, asArr = false): any => {
  const results: any = {}
  const arrResults: any = []
  let key: string

  for (key of hwm) {
    if (
      data.howWeManage &&
      data.howWeManage[key as keyof HowWeManage] &&
      data.howWeManage[key as keyof HowWeManage] !== undefined
    ) {
      if (!results.hasOwnProperty('how_we_manage')) results.how_we_manage = []
      const snakeKey = camelToUnderscore(key)
      if (typeof data.howWeManage[key as keyof HowWeManage] === 'boolean') {
        arrResults.push(snakeKey)
        results.how_we_manage.push(snakeKey)
      } else {
        const item: HowWeItem = {
          label: snakeKey,
          value: data.howWeManage[key as keyof HowWeManage],
        }
        arrResults.push(item)
        results.how_we_manage.push(item)
      }
    }
  }

  if (asArr) {
    return arrResults
  }
  return results
}

export const getWorkFlow = (data: Job | Company, asArr = false): string[] => {
  const results: any = {}
  const arrResults: any = []
  let key: string

  for (key in how_we_test as object) {
    if (
      data.howWeTest &&
      data.howWeTest[key as keyof HowWeTest] &&
      data.howWeTest[key as keyof HowWeTest] !== undefined
    ) {
      if (!results.hasOwnProperty('how_we_test')) results.how_we_test = []
      arrResults.push(key)
      results.how_we_test.push(key)
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (key in how_we_code as object) {
    if (
      data.howWeCode &&
      data.howWeCode[key as keyof HowWeCode] &&
      data.howWeCode[key as keyof HowWeCode] !== undefined
    ) {
      if (!results.hasOwnProperty('how_we_code')) results.how_we_code = []
      if (typeof data.howWeCode[key as keyof HowWeCode] === 'boolean') {
        arrResults.push(key)
        results.how_we_code.push(key)
      } else {
        const item: HowWeItem = {
          label: key,
          value: data.howWeCode[key as keyof HowWeCode],
        }
        arrResults.push(item)
        results.how_we_code.push(item)
      }
    }
  }

  for (key in how_we_manage as object) {
    if (
      data.howWeManage &&
      data.howWeManage[key as keyof HowWeManage] &&
      data.howWeManage[key as keyof HowWeManage] !== undefined
    ) {
      if (!results.hasOwnProperty('how_we_manage')) results.how_we_manage = []
      const snakeKey = camelToUnderscore(key)
      if (typeof data.howWeManage[key as keyof HowWeManage] === 'boolean') {
        arrResults.push(key)
        results.how_we_manage.push(key)
      } else {
        const item: HowWeItem = {
          label: snakeKey,
          value: data.howWeManage[key as keyof HowWeManage],
        }
        arrResults.push(item)
        results.how_we_manage.push(item)
      }
    }
  }

  if (asArr) {
    return arrResults
  }
  return results
}

export const getTools = (data: Job | Company): string[] => {
  const results: any = []
  let key: string

  for (key in toolsStrings) {
    if (
      data.tools &&
      data.tools[key as keyof Tools] &&
      data.tools[key as keyof Tools] !== undefined
    ) {
      results.push(key)
    }
  }

  return results
}

export const getIdFromSlug = (slug: String): string => {
  const arr = slug.split('-')
  const fst = arr.splice(0, 1)

  return fst[0]
}

export const formatDate = (date: Date, local: string) =>
  new Date(date).toLocaleDateString(local, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })

export const nested = (data: any, pid = undefined) =>
  data.reduce((r: any, e: any) => {
    // eslint-disable-next-line eqeqeq
    if (e.parentCommentId == pid) {
      const obj = { ...e }
      const children = nested(data, e.id)
      if (children.length) obj.children = children
      r.push(obj)
    }

    return r
  }, [])

export const getYoutubeId = (url: string) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

export const getReadableLink = (article: Readable, domain: string = '') => {
  let path: string

  if (article.readableType === 'youtube') {
    return article.slug as string
  }
  if (article.slug) {
    path = `/readme/${article.slug}`
  } else if (article.readableType === 'News') {
    path = `/news/${article.readableId}`
  } else {
    path = `/articles/${article.readableId}`
  }

  return `${domain}${path}`
}

export const getHrReadableLink = (article: Readable, domain: string = '') => {
  let path: string
  if (article.forHr) {
    path = `/for-employers/blog/${article.slug}`
  } else {
    path = `/readme/${article.slug}`
  }

  return `${domain}${path}`
}

export const getReadableLinkForGraphqlType = (
  articleData: any,
  domain: string = ''
) => {
  const article = <Readable>{
    readableId: articleData.readableId,
    slug: articleData.slug,
    readableType: articleData.readableType,
  }
  return getReadableLink(article, domain)
}

export const getHrReadableLinkForGraphqlType = (
  articleData: any,
  domain: string = ''
) => {
  const article = <Readable>{
    readableId: articleData.readableId,
    slug: articleData.slug,
    readableType: articleData.readableType,
    forHr: articleData.forHr,
  }
  return getHrReadableLink(article, domain)
}

export const mapLocations = (
  locations: Array<JobLocation> | any,
  locale: String
) => {
  if (!locations) {
    return []
  }
  return locations.map((jobLocation: JobLocation) => {
    const { location } = jobLocation
    if (location) {
      return locale === 'en' ? location.cityEn : location.cityPl
    }
  })
}

export function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

export function jobsArrayIncludesId(
  jobs: Maybe<string[]> | undefined,
  id: string
): boolean {
  if (jobs?.includes(id.split('-')[0])) {
    return true
  }
  return false
}

export function sortTechIcon(a: Tech, b: Tech) {
  const isADefault = a.icon?.url?.includes('default')
  const isBDefault = b.icon?.url?.includes('default')

  if (isADefault && !isBDefault) {
    return 1
  }
  if (!isADefault && isBDefault) {
    return -1
  }
  return 0
}

export function translationSuffix(count: number) {
  if (count === 1) {
    return 'one'
  }
  if (count > 1 && count < 5) {
    return 'some'
  }
  return 'many'
}
export type CaptchaResult = {
  success: boolean
  challenge_ts: string
  hostname: string
  score: number
  action: string
  apk_package_name: string
  app_id: string
  client_sdk_version: string
  recaptcha_version: string
  challenge_ts_tm_diff: number
  score_tag: string
  error: string
}

export const handleReCaptchaVerify = async (): Promise<any> => {
  // @ts-ignore
  const token = await window.grecaptcha.execute(
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    { action: 'submit' }
  )
  const body = { recaptchaResponse: token }
  const response = await fetch('/api/validate', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;chaset=utf-8' },
    body: JSON.stringify(body),
  })
  if (response.ok) {
    const json = await response.json()
    return json as CaptchaResult
  }
  return { success: false, error: response.statusText }
}

export const jobValue = (job: Job) => {
  const mapping: any = {
    senior: 10,
    medium: 5,
    junior: 1,
    intern: 0.5,
  }

  return (
    mapping[job.experienceLevel] *
    (job.internalApplication ? 4 : 1) *
    0.5
  ).toFixed(2)
}

export const technologies = [
  '.NET',
  '.NET Core',
  '.NET Framework',
  'ABAP',
  'Active Directory',
  'ActiveMQ',
  'ADAS',
  'Agile',
  'Airflow',
  'Ajax',
  'Alamofire',
  'AMQP',
  'Android',
  'Angular',
  'AngularJS',
  'Ansible',
  'Ant',
  'Apache',
  'Apache Felix',
  'Apache Jackrabbit Oak',
  'Apache Sling',
  'Apex',
  'Appium',
  'ASP.NET',
  'ASP.NET MVC',
  'Assembly',
  'AssertJ',
  'Autosar',
  'AWS',
  'Azure',
  'Azure Data Factory',
  'Azure DevOps',
  'Bamboo',
  'Bash',
  'BDD',
  'Big Data',
  'Bootstrap',
  'BPMN',
  'C',
  'C#',
  'C++',
  'C++',
  'Cassandra',
  'CD',
  'Chef',
  'CI',
  'CMAKE',
  'COBOL',
  'CocoaPods',
  'Confluence',
  'CSS',
  'CSS3',
  'Cucumber',
  'Cypress',
  'Databricks',
  'DAX',
  'DB2',
  'DevOps',
  'Django',
  'Docker',
  'Docker',
  'Dynatrace',
  'EJB',
  'ElasticSearch',
  'Electron',
  'ELK',
  'Enterprise Architect',
  'Entity Framework',
  'ETL',
  'Excel',
  'Exchange',
  'Express',
  'Fastlane',
  'Figma',
  'Firebase',
  'Flask',
  'Flutter',
  'Foundation',
  'FPGA',
  'Gatling',
  'GCP',
  'Gerrit',
  'Gherkin',
  'Go',
  'Gradle',
  'Grafana',
  'GraphQL',
  'Groovy',
  'Grunt',
  'Gulp',
  'Hadoop',
  'Haskell',
  'Helm',
  'Hibernate',
  'Hive',
  'HP ALM',
  'HTML',
  'IAM',
  'IBM MQ',
  'IIS',
  'Informatica',
  'iOS',
  'ISTQB',
  'ITIL',
  'J2EE',
  'Jasmine',
  'Java',
  'JavaScript',
  'JBoss',
  'Jenkins',
  'JetPack',
  'JIRA',
  'Jira',
  'JMeter',
  'JMS',
  'JPA',
  'jQuery',
  'JSON',
  'JSP',
  'JUnit',
  'Kafka',
  'Karma',
  'Kibana',
  'Kotlin',
  'Kubernetes',
  'Laravel',
  'LESS',
  'Magento',
  'Matlab',
  'Maven',
  'Mercurial',
  'Microservices',
  'Microsoft Azure',
  'Mockito',
  'MongoDB',
  'MS Office',
  'MS SQL',
  'MS SQL Server',
  'Murex',
  'MySQL',
  'Nagios',
  'Neo4j',
  'NestJS',
  'Next.js',
  'Nginx',
  'Node.js',
  'NoSQL',
  'Objective-C',
  'Office 365',
  'OpenAPI',
  'OpenShift',
  'OpenStack',
  'Oracle',
  'Oracle Database',
  'Perl',
  'PHP',
  'PHPUnit',
  'PL/SQL',
  'Postgres',
  'PostgreSQL',
  'Postman',
  'Power BI',
  'Power Platform',
  'PowerBI',
  'PowerShell',
  'Puppet',
  'Pytest',
  'Python',
  'Qt',
  'R',
  'RabbitMQ',
  'RAML',
  'React',
  'React Native',
  'RedHat',
  'Redis',
  'Redux',
  'REST',
  'Retrofit',
  'Robot Framework',
  'RSpec',
  'Ruby',
  'Ruby on Rails',
  'Rust',
  'RxJava',
  'RxSwift',
  'Salesforce',
  'Salesforce MarketingCloud',
  'SAP',
  'SAP Hybris',
  'SAS',
  'Sass',
  'Scala',
  'Scrum',
  'SCSS',
  'Selenium',
  'ServiceNow',
  'SharePoint',
  'SIEM',
  'Sketch',
  'Snowflake',
  'SOAP',
  'SoapUI',
  'SonarQube',
  'Spark',
  'Splunk',
  'Spring',
  'Spring Boot',
  'Spring Cloud',
  'Spring Data',
  'SQL',
  'SQL',
  'SQL Server',
  'SSAS',
  'SSIS',
  'SSRS',
  'STL',
  'Swagger',
  'Swift',
  'Sybase',
  'Symfony',
  'T-SQL',
  'Tableau',
  'Tailwind CSS',
  'TeamCity',
  'Terraform',
  'Tomcat',
  'Travis CI',
  'TypeScript',
  'UIKit',
  'UML',
  'Unity',
  'Unreal Engine',
  'VBA',
  'VMware',
  'Vue.js',
  'WCF',
  'WebLogic',
  'Webpack',
  'WebSphere',
  'Windows',
  'Windows Server',
  'WordPress',
  'WPF',
  'XCTests',
  'XML',
  'XSD',
  'XSLT',
  'Zabbix',
  'Zend',
]
